
import { defineComponent, onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const reference = ref<string>("");

    onBeforeMount(() => {
      reference.value = String(route.params.reference);
    });

    return { reference };
  },
});
