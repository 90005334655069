<template>
  <b-title>
    {{ $t("complement.choice_of_additional_deposit.title", { reference }) }}
  </b-title>
  <b-container>
    <div class="choice">
      <b-btn
        :to="$localePath({ name: 'ComplementBia', params: { reference } })"
      >
        {{ $t("complement.choice_of_additional_deposit.bia_files_button") }}
      </b-btn>
      <span>{{ $t("complement.choice_of_additional_deposit.or") }}</span>
      <b-btn :to="$localePath({ name: 'ComplementQm', params: { reference } })">
        {{ $t("complement.choice_of_additional_deposit.qm_files_button") }}
      </b-btn>
    </div>
  </b-container>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const reference = ref<string>("");

    onBeforeMount(() => {
      reference.value = String(route.params.reference);
    });

    return { reference };
  },
});
</script>

<style lang="scss" scoped>
.choice {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;

  span {
    padding: 20px 0;
    color: var(--title);
    font-weight: 700;
  }
}
</style>
